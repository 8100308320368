/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { AlertResponse, Feature, FeatureType, Group, PersonType, ThingType } from '@eagle/core-data-types';
import { DriverBehaviorEventTypes, DuressEventTypes, EventRecordEventTypes, FeatureTypes, InertiaEventTypes, TrackingEventTypes, VehicleSeatEventTypes } from '@eagle/data-function-types';
import {
  alertFilterToQuery,
  AlertListPage,
  AlertsQuery,
  AlertsTableView,
  AppliedFilter,
  AppliedFilterType,
  CacheDataTypes,
  DateFilterField,
  entityGroup,
  EntityType,
  ErrorPage,
  FeatureIcons,
  FilterDataTypes,
  filterDeletedCache,
  FilterFieldNewProps,
  FilterPathIdentifiers,
  filterToQuery,
  FilterTypes,
  FindItemsDeferredResult,
  FormatTimestamp,
  getListResultDescription,
  MiddleSpinner,
  NewFilter,
  NEW_ALERT_FILTER_FLAG,
  NEW_FILTER_FLAG,
  Pagination,
  PortalFeatureIcons,
  RangeSelectValues,
  SAVED_FILTER_KEY,
  T_MANY,
  useAuthenticated,
  useBoolFlag,
  useDynamicModule,
  useFetchAllCache,
  usePromise,
  useSmallScreen
} from '@eagle/react-common';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum EventOccurrenceTypes {
  AIRBAG_DEPLOYED = 'airbag-deployed',
  VSC_ACTIVATED = 'vsc-activated',
}

export const AlertList: FC = () => {
  const { t } = useTranslation(['common', 'terms', 'track']);
  const { axios, userInfo } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const [refresh, setRefresh] = useState(false);
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
  const initialStartTime = DateTime.now().minus({ hours: 8 });
  const initialEndTime = DateTime.now();
  const enableNewAlertFilter = useBoolFlag(NEW_ALERT_FILTER_FLAG);
  const useNewFilter = useBoolFlag(NEW_FILTER_FLAG);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);

  const groupCache = useFetchAllCache(CacheDataTypes.GROUP);
  const [groups, groupsError, groupsState] = usePromise(
    filterDeletedCache<Group>(groupCache),
    [groupCache],
  );

  const personTypesCache = useFetchAllCache(CacheDataTypes.PERSON_TYPE);
  const [personTypes, personTypesError, personTypesState] = usePromise(
    filterDeletedCache<PersonType>(personTypesCache),
    [personTypesCache],
  );

  const thingTypesCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const [thingTypes, thingTypesError, thingTypesState] = usePromise(
    filterDeletedCache<ThingType>(thingTypesCache),
    [thingTypesCache],
  );

  const myFeatureCache = useFetchAllCache(CacheDataTypes.MY_FEATURE);
  const featureTypeCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);
  const [myFeatures, myFeaturesError, myFeaturesState] = usePromise<Feature[]>(
    myFeatureCache.all(),
    [myFeatureCache],
  );
  const [featureTypes, featuresError, featuresState] = usePromise<FeatureType[]>(
    featureTypeCache.all(),
    [featureTypeCache],
  );

  const dateOptions: DateFilterField[] = useMemo(() => ([
    {
      id: '0',
      data: {
        endTime: (time) => time,
        startTime: (time) => time.minus({ hours: 8 }),
      },
      display: t('common:component.date-time-range.labels.last-hour', { count: 8 }),
    },
    {
      id: '1',
      data: {
        endTime: (time) => time,
        startTime: (time) => time.startOf('day'),
      },
      display: t('common:component.date-time-range.labels.today'),
    },
    {
      id: '2',
      data: {
        endTime: (time) => time.startOf('day'),
        startTime: (time) => time.minus({ days: 1 }).startOf('day'),
      },
      display: t('common:component.date-time-range.labels.yesterday'),
    },
  ]), [t]);

  const formatFilter = useCallback((_id: string, event: string, customKey?: string): EntityType => ({
    _id,
    display: t(customKey ?? `common:event-descriptions-v2.${event}.${_id}.label`, { features: t('common:features.event-record') }),
    properties: {
      definitions: {},
      order: [],
    },
  }), [t]);

  const alertTypes: EntityType[] = useMemo(() => {
    if (enableNewAlertFilter && featureTypes && myFeatures) {
      const newAlertTypes = myFeatures.flatMap((feature) => {
        const selectedFeature = featureTypes.find((item) => item._id === feature.featureTypeId);
        const alertKeys = selectedFeature?.alerts ? Object.keys(selectedFeature.alerts) : [];

        return alertKeys.map((alert) => ({
          featureId: feature._id,
          featureTypeId: feature.featureTypeId,
          alert,
        }));
      });

      return newAlertTypes.map(({ alert, featureId, featureTypeId }) => {
        const featureDisplay = t(`common:features.${featureId}`);
        const alertDisplay = t(`common:alert-descriptions.${featureTypeId}.${alert}.label`);

        return {
          _id: alert,
          alertProperties: {
            feature: featureId,
            featureType: featureTypeId,
            alert,
          },
          display: featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? `${featureDisplay} - ${alertDisplay}` : `${alertDisplay} - ${featureDisplay}`,
          properties: {
            definitions: {},
            order: [],
          },
        };
      });
    }

    return ([
      formatFilter(DuressEventTypes.DURESS_BUTTON_ACTIVATED, FeatureTypes.DURESS_V0, 'common:features.alerts.duress-button-activated'),
      ...Object.values(InertiaEventTypes).map((value) => formatFilter(value, FeatureTypes.INERTIA_V0)),
      ...Object.values(VehicleSeatEventTypes).map((value) => formatFilter(value, FeatureTypes.VEHICLE_SEAT_V0)),
      ...Object.values({ start: TrackingEventTypes.SPEEDING_START, finish: TrackingEventTypes.SPEEDING_FINISH }).map((value) => formatFilter(value, FeatureTypes.TRACKING_V0)),
      ...Object.values(DriverBehaviorEventTypes).map((value) => formatFilter(value, FeatureTypes.DRIVER_BEHAVIOR_V0)),
      ...Object.values(EventRecordEventTypes).map((value) => formatFilter(value, FeatureTypes.EVENT_RECORD_V0)),
      ...Object.values(EventOccurrenceTypes).map((value) => formatFilter(`${value}/${EventRecordEventTypes.OCCURRENCE}`, FeatureTypes.EVENT_RECORD_V0, `common:features.${value}`)),
    ]);
  }, [enableNewAlertFilter, featureTypes, myFeatures]);

  const actions = useMemo(() => ([
    {
      icon: <RefreshIcon />,
      info: <Typography data-chromatic="ignore" variant="caption">{`${t('common:page.alert-list.last-updated.labels')} `}<FormatTimestamp value={lastRefresh} format="relative" /></Typography>,
      label: t('common:common.action.refresh'),
      onClick: () => {
        setRefresh((prev) => !prev);
      },
    },
  ]), [lastRefresh]);

  const findAlerts = useCallback(({ filters, pagination, search, dateRange, savedSelection }: AlertsQuery): FindItemsDeferredResult<AlertResponse> => {
    const cancelToken = Axios.CancelToken.source();
    let dateRangeStart = dateRange?.startTime.toUTC().toISO() ?? initialStartTime.toUTC().toISO();
    let dateRangeFinish = dateRange?.endTime.toUTC().toISO() ?? initialEndTime.toUTC().toISO();

    if (!smallScreen && savedSelection !== RangeSelectValues.PICK_CUSTOM_RANGE.key) {
      for (const [key] of Object.entries(RangeSelectValues)) {
        if (RangeSelectValues[key].key === savedSelection) {
          dateRangeStart = DateTime.now().minus({ hours: RangeSelectValues[key].hours }).toUTC().toISO();
          dateRangeFinish = initialEndTime.toUTC().toISO();
        }
      }
    }

    const alertThingFilters = filters.alerts.filter(({ pathIdentifier }) => pathIdentifier === FilterPathIdentifiers.THING);
    const alertPersonFilters = filters.alerts.filter(({ pathIdentifier }) => pathIdentifier === FilterPathIdentifiers.PERSON);

    const alertFilters = filters.alerts.filter(({ pathIdentifier }) => !pathIdentifier);
    const thingFilters = [...filters.things, ...alertThingFilters];
    const personFilters = [...filters.persons, ...alertPersonFilters];

    return {
      cancel: cancelToken.cancel,
      promise: axios.get<PaginatedResponse<AlertResponse>>('/api/v2/alert', {
        cancelToken: cancelToken.token,
        params: {
          ...pagination,
          dateRangeStart,
          dateRangeFinish,
          filter: enableNewAlertFilter ? alertFilterToQuery(alertFilters) : filterToQuery(alertFilters),
          ...(search ? { search } : {}),
          sort: '-occurred',
          thingFilter: filterToQuery(thingFilters),
          personFilter: filterToQuery(personFilters),
        },
      }).then((response) => {
        setLastRefresh(new Date());
        const filters = [...alertFilters, ...thingFilters, ...personFilters];
        const resultDescription = getListResultDescription({ count: response.data.count, entityKey: 'common:terms:alert', filters, search, t });

        return {
          result: {
            results: response.data.items,
            itemCount: response.data.count ?? 0,
          },
          resultDescription,
        };
      }),
    };
  }, [smallScreen, userInfo.accountId]);

  const renderContent = useCallback((
    alerts: AlertResponse[],
    isLoading: boolean,
    matchCount: number,
    pagination: Pagination,
    setPagination: (value: Pagination) => void,
  ) => {
    return (
      <AlertsTableView
        alerts={alerts}
        isLoading={isLoading}
        matchCount={matchCount}
        pagination={pagination}
        setPagination={setPagination}
        showGroups={true}
        showPerson={true}
        showThing={true}
        showFeature={false}
        stickyHeader={false}
        handleShowMore={() => { }}
        isRecentAlertsView={false}
      />
    );
  }, []);

  const renderFilterContent = useCallback((
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ): JSX.Element => {
    const filterFields: FilterFieldNewProps[] = [
      {
        dataType: FilterDataTypes.ALERT,
        entityTypes: alertTypes ?? [],
        fieldLabel: t('common:component.filter.labels.select-an-alert-type'),
        propertyLabel: t('common:terms.alert-type', { count: T_MANY }),
        typePropertyName: FilterTypes.ALERT_TYPE,
      },
      {
        apiUrl: '/api/v1/person',
        attributes: {
          typeCache: personTypesCache,
          typePath: FilterTypes.PERSON_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-person'),
        pathIdentifier: FilterPathIdentifiers.PERSON,
        propertyLabel: t('terms:person', { count: T_MANY }),
        typePropertyName: FilterTypes.PERSON,
      },
      {
        apiUrl: '/api/v1/thing',
        attributes: {
          typeCache: thingTypesCache,
          typePath: FilterTypes.THING_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-thing'),
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('terms:thing', { count: T_MANY }),
        typePropertyName: FilterTypes.THING,
      },
      {
        dataType: FilterDataTypes.CACHE,
        entityCache: groupCache,
        fieldLabel: t('common:component.filter.labels.select-a-group'),
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('common:terms.group', { count: T_MANY }),
        typePropertyName: FilterTypes.GROUP,
      },
    ];

    return (
      <NewFilter
        filterFields={filterFields}
        filters={filters}
        onCloseClicked={() => setFilterOpen(false)}
        onFiltersChanged={onFiltersChanged}
        savedFilterKey={SAVED_FILTER_KEY}
        storageKey="alerts"
        data-testid="alerts-new-filter"
      />
    );
  }, [alertTypes]);

  if (groupsError) return <ErrorPage error={groupsError} />;
  if (personTypesError) return <ErrorPage error={personTypesError} />;
  if (thingTypesError) return <ErrorPage error={thingTypesError} />;
  if (myFeaturesError) return <ErrorPage error={myFeaturesError} />;
  if (featuresError) return <ErrorPage error={featuresError} />;

  if ([personTypesState, thingTypesState, groupsState, myFeaturesState, featuresState].includes('pending') || !moduleLoaded) {
    return <MiddleSpinner />;
  }

  return (
    <AlertListPage
      actions={actions}
      data-testid='alert-list'
      dateOptions={dateOptions}
      defaultDateRange={{ startTime: initialStartTime, endTime: initialEndTime }}
      defaultDateLabel={t('common:component.date-time-range.labels.last-eight-hours')}
      entityTypes={{
        alerts: alertTypes ?? [],
        group: groups ? entityGroup(groups) : [],
        persons: [],
        personTypes: personTypes ?? [],
        things: [],
        thingTypes: thingTypes ?? [],
      }}
      icon={module?.AlertIcon && <module.AlertIcon />}
      onQueryChanged={findAlerts}
      onRefresh={refresh}
      renderContent={renderContent}
      renderFilterContent={useNewFilter ? renderFilterContent : undefined}
      setLastRefresh={setLastRefresh}
      setRefresh={setRefresh}
      title={t('common:terms.alert', { count: T_MANY })}
      useNewFilter={useNewFilter}
    />
  );
};
